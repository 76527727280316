:root {
    font-size: 16px;
    --bg-primary: #2f3129;
    --bg-secondary: #272822;
    --accent-primary: #d3552e;
    --accent-secondary: #5e3124;
    --accent-hover: #ff6738;
    --font-primary: #cccccc;
    --font-disabled: #444444;
    --selected-red: #b11900;
    --selected-green: #00d7a2;
    --cursor-default: url('assets/cursor/arrow.png'), auto;
    --cursor-pointer: url('assets/cursor/pointer.png'), auto;
}

* {
    cursor: var(--cursor-default);
}

html {
    scroll-behavior: smooth;
}

.hidden {
    display: none !important;
}

/* Custom scroll bar */
*::-webkit-scrollbar {
    width: 4px;
}
*::-webkit-scrollbar-track {
    background: var(--bg-primary);
}
*::-webkit-scrollbar-thumb {
    background: var(--accent-primary);
    border-radius: 10px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg-primary);
}

a {
    color: var(--accent-primary);
    text-decoration: none;
}
a:hover {
    cursor: var(--cursor-pointer);
    color: var(--accent-hover);
}
a *:hover {
    cursor: var(--cursor-pointer);
}

/* Overrides to look like an <a> tag */
.anchor {
    color: var(--accent-primary) !important;
    text-decoration: none;
}
.anchor * {
    color: var(--accent-primary) !important;
}
.anchor:hover {
    cursor: var(--cursor-pointer);
    color: var(--accent-hover) !important;
}
.anchor *:hover {
    cursor: var(--cursor-pointer);
    color: var(--accent-hover) !important;
}

.cursor-pointer * {
    cursor: var(--cursor-pointer) !important;
}

/* Stupid overrides */
.MuiAccordionSummary-root:hover:not(.Mui-disabled) {
    cursor: var(--cursor-pointer) !important;
}
.MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
}

*:focus {
    outline: 0;
}

.MuiFormControlLabel-root {
    cursor: var(--cursor-default) !important;
}
